.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.works-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    max-height: 90%;
    vertical-align: middle;
    display: table-cell;

    h1 {
      font-size: 60px;
      font-family: Coolvetica;
      font-weight: 400;
      color: #ffd700;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
    }
    h2 {
      font-size: 30px;
      font-family: Coolvetica;
      font-weight: 400;
      color: #ffd700;
      margin-top: 60px;
      position: relative;

      left: 10px;
    }
  }
  p {
    font-size: 18px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }
}

.showcase {
  display: grid;
  grid-template-columns: 400px 400px 400px 400px;
  grid-template-rows: 450px 450px;
  margin-left: 80px;
  color: #ffd700;
}

.block {
  background: #fff;
  margin: 25px;
}
