.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    max-height: 90%;
    vertical-align: middle;
    display: table-cell;

    h1 {
      font-size: 60px;
      font-family: Coolvetica;
      font-weight: 400;
      color: #ffd700;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
    }
    h2 {
      font-size: 30px;
      font-family: Coolvetica;
      font-weight: 400;
      color: #ffd700;
      margin-top: 60px;
      position: relative;

      left: 10px;
    }
  }
  p {
    font-size: 18px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }
}

.table {
  display: grid;
  grid-template-columns: 15% 85%;
  max-width: 520px;
  margin-top: -20px;
}

.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.bold {
  font-weight: 600;
  color: #ffd700;
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }

  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}
