.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.contact-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    max-height: 90%;
    vertical-align: middle;
    display: table-cell;

    h1 {
      font-size: 60px;
      font-family: Coolvetica;
      font-weight: 400;
      color: #ffd700;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
    }
    p {
      font-size: 18px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
}

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}
